import {
  TradingBlockTransferDirectionDto,
  TradingBlockTransferMechanismDto,
  TransferDetailsDto,
  TransferDto,
  TransferPlaidInstitutionDto,
} from 'src/dtos';
import { Transfer, TransferDetails, TransferDirection, TransferStatus } from 'src/models/transfers.models';

const getDescription = (dto: TransferDto): string => {
  if (dto.direction === TradingBlockTransferDirectionDto.Incoming) {
    if (dto.mechanism === TradingBlockTransferMechanismDto.ACH) {
      return 'ACH Deposit(s)';
    }

    if (dto.mechanism === TradingBlockTransferMechanismDto.Check) {
      return 'Check Deposit(s)';
    }

    if (dto.mechanism === TradingBlockTransferMechanismDto.Wire) {
      return 'Wire Deposit(s)';
    }
  }

  if (dto.mechanism === TradingBlockTransferMechanismDto.ACH) {
    return 'ACH Withdrawal(s)';
  }

  if (dto.mechanism === TradingBlockTransferMechanismDto.Check) {
    return 'Check Withdarawal(s)';
  }

  return 'Wire Withdrawal(s)';
};

const getFrom = (dto: TransferDto | TransferDetailsDto): string | undefined => {
  if (dto.direction === TradingBlockTransferDirectionDto.Incoming) {
    return dto.bankName ?? undefined;
  }

  return `My IPO-#${dto.accountId}`;
};

const getTo = (dto: TransferDto | TransferDetailsDto): string | undefined => {
  if (dto.direction === TradingBlockTransferDirectionDto.Outgoing) {
    return dto.bankName;
  }

  return `My IPO-#${dto.accountId}`;
};

export const mapTransferDtoToModel = (dto: TransferDto & Partial<TransferPlaidInstitutionDto>): Transfer => ({
  id: dto.id,
  from: getFrom(dto),
  to: getTo(dto),
  description: getDescription(dto),
  accountId: dto.accountId,
  amount: dto.direction === TradingBlockTransferDirectionDto.Incoming ? dto.amount : dto.requestedAmount,
  mechanism: dto.mechanism,
  direction: new TransferDirection(dto.direction),
  status: new TransferStatus(dto.state),
  createdAt: new Date(dto.createDate).toISOString(),
  updatedAt: dto.lastModifiedDate
    ? new Date(dto.lastModifiedDate).toISOString()
    : new Date(dto.createDate).toISOString(),
  plaidInstitutionId: dto?.institutionId,
  bankName: dto.bankName,
  bankLogo: dto.logo,
});

export const mapTransferDetailsDtoToModel = (dto: TransferDetailsDto): TransferDetails => ({
  id: dto.id,
  from: getFrom(dto),
  to: getTo(dto),
  description: getDescription(dto),
  accountId: dto.accountId,
  amount: dto.direction === TradingBlockTransferDirectionDto.Incoming ? dto.amount : dto.requestedAmount,
  mechanism: dto.mechanism,
  bankName: dto.bankName,
  direction: new TransferDirection(dto.direction),
  status: new TransferStatus(dto.state),
  createdAt: new Date(dto.createDate).toISOString(),
  updatedAt: dto.lastModifiedDate
    ? new Date(dto.lastModifiedDate).toISOString()
    : new Date(dto.createDate).toISOString(),
});
