import { mask, unmask } from 'src/utils';

import { MFormInput, MFormInputProps } from '../MFormInput/MFormInput';

export interface SsidInputProps extends Omit<MFormInputProps, 'label' | 'value'> {
  value?: string;
  testId?: string;
}

const SSID_MASK = '###-##-####';

export const SsidInput = ({ value, defaultValue, error, onChange, disabled, testId }: SsidInputProps) => {
  return (
    <MFormInput
      label='Social Security Number'
      tooltip={{
        type: 'modal',
        title: 'Why do we need this?',
        content: (
          <div>
            <p>
              A social security number is required to comply with security regulatory requirements, My IPO must get
              information such as name, address, DOB, social security number, employment information and basic financial
              information.
            </p>
            <p>
              My IPO’s site is secure, and we do not share your personal information as per our privacy policy. As part
              of our regulatory requirements, we must follow very strict security measures as a financial firm. Your
              social security number is encrypted at account creation you will notice we only show the last 4 digits of
              your SSN after logging into My IPO once your account is created.
            </p>
          </div>
        ),
      }}
      value={mask({ value, mask: SSID_MASK, substitute: '#' })}
      defaultValue={defaultValue}
      placeholder='###-##-####'
      error={error}
      onChange={value => {
        onChange(unmask({ value, mask: SSID_MASK, substitute: '#', separators: ['-'] }));
      }}
      disabled={disabled}
      testId={testId}
    />
  );
};
