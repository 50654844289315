export enum TradingBlockTransferMechanismDto {
  Wire = 'Wire',
  ACH = 'Ach',
  Check = 'Check',
}

export enum TradingBlockTransferDirectionDto {
  Incoming = 'Incoming',
  Outgoing = 'Outgoing',
}

export enum TradingBlockTransferStateDto {
  PendingRepCreateApproval = 'PendingRepCreateApproval',
  PendingFirmCreateApproval = 'PendingFirmCreateApproval',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Canceled = 'Canceled',
  Approved = 'Approved',
  FundsHeld = 'FundsHeld',
  FundsPosted = 'FundsPosted',
  SentToBank = 'SentToBank',
  Complete = 'Complete',
  PendingPrinting = 'PendingPrinting',
  Void = 'Void',
  Returned = 'Returned',
  StopPayment = 'StopPayment',
  CreateRejected = 'CreateRejected',
  Postponed = 'Postponed',
  FailedAtBank = 'FailedAtBank',
  Requested = 'Requested',
}

export interface TransferDto {
  id: number;
  accountId: number;
  transferId: null | number;
  externalTransferId: string;
  mechanism: TradingBlockTransferMechanismDto;
  direction: TradingBlockTransferDirectionDto;
  achRelationship: number;
  state: TradingBlockTransferStateDto;
  amount: number;
  requestedAmount: number;
  memos: string[];
  fees: number[];
  bankName?: string;
  createDate: string;
  lastModifiedDate?: string;
  deletedDate?: string;
  rejectReason?: string;
}

export interface TransferPlaidInstitutionDto {
  institutionId: string;
  name?: string;
  logo?: string;
}

export interface TransferDetailsDto extends TransferDto {}
