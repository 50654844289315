import React from 'react';

import { cx } from '@emotion/css';
import { Col, Row } from 'antd';
import { BaseStyle, Display, JustifyContent } from 'src/styles';

import * as Styles from './ModalField.styles';

interface ModalFieldProps {
  label: string;
  value: JSX.Element | string | number;
}

export const ModalField = ({ label, value }: ModalFieldProps) => (
  <Row className={cx(BaseStyle.level, Styles.container)}>
    <Col span={10} className={cx(Styles.borderBottom, Styles.fieldLabel)}>
      {label}
    </Col>
    <Col
      span={14}
      className={cx(Styles.alignRight, Styles.borderBottom, Styles.fieldValue, Display.flex, JustifyContent.flexEnd)}>
      {value}
    </Col>
  </Row>
);
